import api from '@shared/api';
import { decoder } from '@shared/utils/jwt';
import { formatSlackMessage } from '@shared/utils/slack';
import { ANALYTICS_URL } from '~/api/constants';

type ErrorReportType = {
  message?: string;
  shop?: string;
  productId?: string;
  accessToken?: string;
  [key: string]: any;
};

export function sendErrorReport({
  message,
  shop,
  productId,
  accessToken,
  ...rest
}: ErrorReportType) {
  (async () => {
    const body = {
      environment: process.env.NEXT_PUBLIC_MODE || process.env.NODE_ENV,
      message: message || '',
      from: '단일상품 상품 SDK',
      company: shop || 'oksoon',
      productId: productId || '',
      pathname: window.location.pathname,
      ...(accessToken && { user_token: decoder(accessToken) }),
      ...rest,
    };

    // slack
    await api({
      url: `/api/error-report`,
      method: 'POST',
      data: {
        message: formatSlackMessage(body),
      },
    });

    // kibana
    await api({
      baseURL: ANALYTICS_URL,
      url: '/log/record',
      method: 'post',
      headers: {
        'x-api-key':
          process.env.NEXT_PUBLIC_MODE === 'production'
            ? process.env.NEXT_PUBLIC_ANALYTICS_API_KEY
            : process.env.NEXT_PUBLIC_ANALYTICS_DEV_API_KEY,
      },
      data: {
        path: '/stat/error/client',
        ...body,
      },
    });
  })();
}
